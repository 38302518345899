.scroll-move {
    position: relative;
    height: 324px;
    @include media-breakpoint-down(xxl){
        height: 223px; 
    }
    @include media-breakpoint-down(xl){
        height: 178px; 
    }
    @include media-breakpoint-down(lg){
        height: 150px; 
    }
    @include media-breakpoint-down(md){
        height: 95px; 
    }    
    .scrolling-text {
        position:absolute;
        white-space: nowrap;
        transition: all 1s;
    }
} 