
.shop-image-wrapper{    
    .shop-image-hover{
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 102px);
        opacity: 0;
        transition: opacity 0.4s ease-in-out;

        .btn-sm{
            --#{$prefix}btn-font-size: 18px;
        }
    }
    &::before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        backdrop-filter: blur(5px);
        background-color: rgba(37, 59, 47, 0.38);
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.4s ease-in-out;
    }
    &:hover{  
        &::before,       
        .shop-image-hover{         
            opacity: 1;
        }
    }
}

.shop-button{
    border: 0;
    padding: 0;
}

.shop-details{
    .product-title{
        border-bottom: 2px solid transparent;
        transition: all .3s ease-in-out;
        padding-bottom: 2px;
        color: var(--#{$prefix}body-color);
    }

    &:hover{
        .product-title{
            border-bottom: 2px solid var(--#{$prefix}border-color);
        }
    }
}

.product-price{
    color: var(--#{$prefix}primary);
}

.shop-description-tabs{
    gap: 80px;
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--#{$prefix}secondary);
    flex-wrap: wrap;
    @include media-breakpoint-down(lg){
        gap: 40px;
    }
    @include media-breakpoint-down(md){
        gap: 20px;
        .fs-4{
            font-size: 20px;
        }
    }
    @include media-breakpoint-down(sm){
        gap: 18px;
        .fs-4{
            font-size: 18px;
        }
    }
}
.shop-description-button{
    background: none;
    padding: 0;
    border: 0;
    position: relative;
    width: fit-content;
    padding: 8px 0;
    &::after{
        content: "";
        position: absolute;
        bottom: -7px;
        width: 100%;
        height: 4px;
        left: 0;
        background: var(--#{$prefix}primary);
        opacity: 0;
        transition: opacity .2s ease-in-out;
        @include media-breakpoint-down(md){
            bottom: -7px;
        }
    }
    &.active{
        &::after{
            opacity: 1;
        }
    }
}
.list-description{
    min-width: 120px;
}

#offcanvasCart{
    &.offcanvas{
       --#{$prefix}offcanvas-width: 512px;
       --#{$prefix}offcanvas-height: 100vh;
       .offcanvas-body{
            position: relative;
       }
    }
}
.cart-items{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cart-items-heading{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 0;
    @include media-breakpoint-down(xxl){
        font-size: 18px;
    }
}
.offcanvas-cart-btn{
    --#{$prefix}cart-btn-padding-top: 268px;
    padding-top: var(--#{$prefix}cart-btn-padding-top);
    .btn-secondary{
        --#{$prefix}btn-hover-color: var(--#{$prefix}primary);
        --#{$prefix}btn-active-color: var(--#{$prefix}primary); 
        --#{$prefix}btn-hover-border-color: var(--#{$prefix}primary); 
    }
    @include media-breakpoint-down(xxl){
        --#{$prefix}cart-btn-padding-top: 180px;
    }    
    @include media-breakpoint-down(lg){
        --#{$prefix}cart-btn-padding-top: 150px;
    }
    @include media-breakpoint-down(sm){
        .btn-md{
            --#{$prefix}btn-padding-x : .7rem;
            --#{$prefix}btn-font-size: 18px;
        }
    }
}

.description-details{
    max-width: 960px;
}

@include media-breakpoint-down(md){
    .cart-items-heading{
        font-size: 16px;
    }
    .cart-items span{
        font-size: 15px;
    }
}

.star-icon{
    li{
        font-size: 22px;
        line-height: 1.2;
    }
}
.credits-wrapper{    
    a{
        text-decoration: none;
        transition: all .3s ease-in-out;
        display: inline-block;
        border-bottom: 1px solid transparent;       
    }
    &:hover{        
        a{
            border-bottom: 1px solid var(--#{$prefix}border-color);            
        }
    } 
}