$prefix: bs-;


// ==============Spacers

  $spacer: 1rem;
  $spacers: (
    0: 0,
    1: $spacer * .25,
    2: $spacer * .5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    10: $spacer * .625,
    20: $spacer * 1.25,
    30: $spacer * 1.875,
    40: $spacer * 2.5,
    60: $spacer * 3.75,
    70: $spacer * 4.375,
    90: $spacer * 5.625,
    100: $spacer * 6.25,
    120: $spacer * 7.5,
    130: $spacer * 8.125,
    160: $spacer * 10,
    200: $spacer * 12.5,
  );
// ==============Spacers




// ==============Typography
  $display-font-weight: 700; 
  $headings-font-weight :700;

  // $font-family-sans-serif: 'Plus Jakarta Sans', sans-serif;
// ==============Typography



// ==============Buttons

  $btn-padding-x : 2.32rem;
  $btn-padding-y : 0.8745rem; // btn-height 68px
  $btn-border-width : 2px;
  $btn-font-weight : 700;
  $btn-font-size : 1.5rem; // font-size:24px
  $btn-border-radius: 0;

  $btn-padding-x-xl : 1.906rem;
  $btn-padding-y-xl : 1.875rem; // btn-height 100px
  $btn-border-radius-xl : 0;
  $btn-font-size-xl: 1.5rem;

  $btn-padding-x-lg : 1.125rem;
  $btn-padding-y-lg : 1.165rem; // btn-height 75px
  $btn-border-radius-lg : 0;

  $btn-padding-x-md : 1.874rem;
  $btn-padding-y-md : .563rem; // btn-height 58px
  $btn-border-radius-md : 0;
  $btn-font-size-md : 1.5rem;

  $btn-font-size-sm : 1rem; // font-size:16px
  $btn-padding-x-sm : 2.07rem;
  $btn-padding-y-sm : .566rem; // btn-height 46px
  $btn-border-radius-sm : 0;

  $btn-font-size-xs : 14px; 
  $btn-padding-x-xs : .7rem;
  $btn-padding-y-xs : .2rem; 
  $btn-border-radius-xs : 0;
// ==============Buttons



// ==============Font Sizes

  $font-size-base: 1.125rem; //18px

  $h1-font-size:                9.375rem; //150px
  $h2-font-size:                8.125rem; //130px
  $h3-font-size:                3.75rem; //60px
  $h4-font-size:                2.1875rem; //35px
  $h5-font-size:                1.5rem; //24px
  $h6-font-size:                $font-size-base; //18px
  $small-font-size:             14px;

  $display-font-sizes: (
    1: 20.25rem, //324px
    2: 12.5rem, //200px  
    3: 5.625rem, // 90px
    4: 3.125rem, // 50px
    5: 3rem, // 48px
    6: 2.5rem // 40px
  );
// ==============Font Sizes



$card-border-radius                 :0;
// ============== Nav

$nav-link-padding-x:                1.75rem; // 28px
$nav-link-font-size:                1.25rem; // 20px
$nav-link-font-weight:              500;

$navbar-padding-y:                  0;
$navbar-nav-link-padding-x:         1.75rem; // 28px
$navbar-light-color:                var(--#{$prefix}primary);
$navbar-light-hover-color:          var(--#{$prefix}primary);
$navbar-dark-hover-color:           var(--#{$prefix}primary);
$navbar-light-active-color:         var(--#{$prefix}secondary);
$navbar-dark-active-color:          var(--#{$prefix}secondary);
// ============== Nav



// ==============zindex-levels-map

  $zindex-levels: (
    n1: -1,
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    5: 5,
    9: 9,
    99: 99,
    999: 999,
    9999: 9999,
  );
// ==============zindex-levels-map


$enable-negative-margins:     true;
$enable-important-utilities:  false;


$utilities: (
  "stroke-color": (
      property: --#{$prefix}stroke-rgb,
      class: stroke,
      values: (
          "primary": var(--#{$prefix}primary-rgb),
          "secondary": var(--#{$prefix}secondary-rgb),
          "danger": var(--#{$prefix}danger-rgb),
          "info": var(--#{$prefix}info-rgb),
          "success": var(--#{$prefix}success-rgb),
          "warning": var(--#{$prefix}warning-rgb),
          "dark": var(--#{$prefix}dark-rgb),
          "light": var(--#{$prefix}light-rgb),
          "black": var(--#{$prefix}black-rgb),
          "white": var(--#{$prefix}white-rgb),        
      )
  ),
  "stroke-opacity": (
      property: --#{$prefix}stroke-opacity,
      class: stroke-opacity,
      values: (
        10: .1,
        20: .2,
        30: .3,
        40: .4,
        50: .5,
        60: .6,
        70: .7,
        80: .8,
        90: .9,
        100: 1
      )
    ),
    "stroke-width": (
      property: --#{$prefix}stroke-width,
      class: stroke-width,
      values: (
        1: 1px,
        2: 2px,
        3: 3px        
      )
    ),
    
);

$carousel-control-width:             0;
$carousel-indicator-width:           var(--#{$prefix}carousel-indicator-width, 220px);
$carousel-indicator-height:          40px;
$carousel-indicator-hit-area-height: 0;
$carousel-indicator-spacer:          0;
$carousel-indicator-opacity:         1;
$carousel-indicator-active-bg:       transparent;
$carousel-indicator-active-opacity:  1;
$carousel-dark-indicator-active-bg:  transparent;


$pagination-border-width:           2px;
$pagination-focus-bg:               var(--#{$prefix}body-bg);
$pagination-focus-box-shadow:       none;


$accordion-button-active-bg:              var(--#{$prefix}body-bg);
$accordion-button-active-color:           var(--#{$prefix}primary);

$accordion-padding-y:                     1.25rem;
$accordion-padding-x:                     3rem;



$card-title-color:                  var(--#{$prefix}primary);
$card-subtitle-color:               var(--#{$prefix}body-color);
$card-border-width:                 2px;
$card-border-color:                 var(--#{$prefix}primary);
$card-border-radius:                0;